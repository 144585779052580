import React, { useState, useEffect, useCallback } from "react";

import { NavLink } from "react-router-dom";
import {
  ImageContent,
  SidebarContainer,
  SidebarContent,
  SidebarHeader,
  ToggleButton,
  Overlay,
} from "./styles";

import ModalRecADM from "../Modal/RecuperacaoADM";
import ModalCancelarInscricao from "../Modal/CancelarInscricao";

import api from "../../services/api";
import Auth from "../../services/auth";
import { isAuthenticated, isAuthenticatedAdmin } from "../../../src/auth";

import Logo from "../../Assets/Images/logo.png";
import Menu from "../../Assets/Images/menu.png";
import Close from "../../Assets/Images/cancel.png";
import LogOut from "../../Assets/Images/logout.png";

import { USER_ADMINISTRADOR, USER_DOADOR, USER_PUBLIC } from "../../App";

function Sidebar() {
  const [isOpenSidebar, setIsOpenSidebar] = useState(false);

  const [showModalRecAdm, setShowModalRecAdm] = useState(false);
  const [showModalCancelarInscricao, setShowModalCancelarInscricao] =
    useState(false);

  const [grupoDoadoresPage, setGrupoDoadoresPage] = useState(false);
  const [agendamentoPage, setAgendamentoPage] = useState(false);

  const [typeUser, setTypeUser] = useState(
    isAuthenticatedAdmin()
      ? USER_ADMINISTRADOR
      : isAuthenticated()
      ? USER_DOADOR
      : USER_PUBLIC
  );

  const cpf_doador = Auth.token();

  function convertToJavaScriptDate(inputDate) {
    const parts = inputDate.split(" - ");
    const datePart = parts[0];
    const timePart = parts[1];
    const [day, month, year] = datePart.split("/");
    const [hours, minutes] = timePart.split(":");
    return new Date(year, month - 1, day, hours, minutes);
  }

  const fetchDataCalendar = useCallback(async () => {
    try {
      const response = await api.get(`/calendars?mult=${true}`);

      let isPeriodAgendamento = false;
      if (response.data.calendarios?.length > 0) {
        isPeriodAgendamento = true;
      }

      let isMultiAgendamentos = false;
      await api.get(`/users/?cpf=${cpf_doador}`).then((response) => {
        if (response.data.doadores[0].agendamento_multiplo != null)
          isMultiAgendamentos = true;
      });

      if (isMultiAgendamentos) {
        setGrupoDoadoresPage(true);
      } else {
        setGrupoDoadoresPage(false);
      }

      if (isPeriodAgendamento) {
        setAgendamentoPage(
          response.data.calendarios.some((calendario) => {
            const startDate = convertToJavaScriptDate(calendario.data_inicio);
            const endDate = convertToJavaScriptDate(calendario.data_termino);
            const today = new Date();

            return startDate <= today && endDate >= today;
          })
        );
      }

      if (agendamentoPage === false) {
        const doadorInscrito = await api.get(`/scheduling?cpf=${cpf_doador}`);
        if (doadorInscrito.data[0].cpf_doador) {
          setAgendamentoPage(true);
        }
      }
    } catch (error) {
      console.log(error);
      setGrupoDoadoresPage(false);
      setAgendamentoPage(false);
    }
  }, [cpf_doador, setAgendamentoPage, setGrupoDoadoresPage]);

  useEffect(() => {
    fetchDataCalendar();
  }, [fetchDataCalendar]);

  const toggleSidebar = () => {
    setIsOpenSidebar(!isOpenSidebar);
  };

  const closeSidebar = () => {
    setIsOpenSidebar(false);
  };

  return (
    <>
      {isOpenSidebar && <Overlay onClick={closeSidebar} />}
      <SidebarContainer
        isOpenSidebar={isOpenSidebar}
        onMouseEnter={() => setIsOpenSidebar(true)}
        // onMouseLeave={() => setIsOpenSidebar(false)}
      >
        <div>
          <SidebarHeader isOpenSidebar={isOpenSidebar}>
            {isOpenSidebar && (
              <div>
                <img src={Logo} alt="Logo Doação de Sangue" />
                <h1>Doação de Sangue</h1>
              </div>
            )}
            <ToggleButton onClick={toggleSidebar}>
              <ImageContent
                src={isOpenSidebar ? Close : Menu}
                alt={isOpenSidebar ? "Close" : "Menu"}
              />
            </ToggleButton>
          </SidebarHeader>
          {isOpenSidebar && (
            <SidebarContent>
              {typeUser === USER_PUBLIC ? (
                <>
                  <NavLink
                    onClick={() => setIsOpenSidebar(false)}
                    exact
                    to="Home"
                  >
                    Home
                  </NavLink>
                  <NavLink
                    onClick={() => setIsOpenSidebar(false)}
                    exact
                    to="Login"
                  >
                    Login
                  </NavLink>
                  <NavLink
                    onClick={() => setIsOpenSidebar(false)}
                    exact
                    to="Register"
                  >
                    Cadastro
                  </NavLink>
                  <NavLink
                    onClick={() => setIsOpenSidebar(false)}
                    exact
                    to="Info"
                  >
                    Informações
                  </NavLink>
                  <NavLink
                    onClick={() => setIsOpenSidebar(false)}
                    exact
                    to="Contato"
                  >
                    Contato
                  </NavLink>
                  <NavLink
                    onClick={() => setIsOpenSidebar(false)}
                    exact
                    to="Ajuda"
                  >
                    Ajuda
                  </NavLink>
                </>
              ) : typeUser === USER_DOADOR ? (
                <>
                  {grupoDoadoresPage && (
                    <NavLink
                      onClick={() => setIsOpenSidebar(false)}
                      exact
                      to="MeuGrupoDoadores"
                    >
                      Meu Grupo de Doadores
                    </NavLink>
                  )}
                  {agendamentoPage && (
                    <NavLink
                      onClick={() => setIsOpenSidebar(false)}
                      exact
                      to="MeuAgendamento"
                    >
                      Agendamento Ativo
                    </NavLink>
                  )}
                  <NavLink
                    onClick={() => setIsOpenSidebar(false)}
                    exact
                    to="MinhasDoacoes"
                  >
                    Meus Agendamentos
                  </NavLink>
                  <NavLink
                    onClick={() => setIsOpenSidebar(false)}
                    exact
                    to="ConfirmData"
                  >
                    Atualizar Dados
                  </NavLink>
                  <NavLink
                    onClick={() => setIsOpenSidebar(false)}
                    exact
                    to="Info"
                  >
                    Informações
                  </NavLink>
                  <NavLink
                    onClick={() => setIsOpenSidebar(false)}
                    exact
                    to="Contato"
                  >
                    Contato
                  </NavLink>
                  <NavLink
                    onClick={() => setIsOpenSidebar(false)}
                    exact
                    to="Ajuda"
                  >
                    Ajuda
                  </NavLink>
                </>
              ) : typeUser === USER_ADMINISTRADOR ? (
                <>
                  {agendamentoPage && (
                    <NavLink
                      onClick={() => setIsOpenSidebar(false)}
                      exact
                      to="MeuAgendamento"
                    >
                      Agendamento Ativo
                    </NavLink>
                  )}
                  <NavLink
                    onClick={() => setIsOpenSidebar(false)}
                    exact
                    to="MinhasDoacoes"
                  >
                    Meus Agendamentos
                  </NavLink>
                  {grupoDoadoresPage && (
                    <NavLink
                      onClick={() => setIsOpenSidebar(false)}
                      exact
                      to="MeuGrupoDoadores"
                    >
                      Meu Grupo de Doadores
                    </NavLink>
                  )}
                  <p>{/*Linha*/}</p>
                  <NavLink
                    onClick={() => setIsOpenSidebar(false)}
                    exact
                    to="AtualizarCadastro"
                  >
                    Atualizar Cadastro
                  </NavLink>
                  <span onClick={() => setShowModalRecAdm(!showModalRecAdm)}>
                    Restaurar Senha
                  </span>
                  <span
                    onClick={() =>
                      setShowModalCancelarInscricao(!showModalCancelarInscricao)
                    }
                  >
                    Cancelar Inscrição
                  </span>
                  <p>{/*Linha*/}</p>
                  <NavLink
                    onClick={() => setIsOpenSidebar(false)}
                    exact
                    to="Agendas"
                  >
                    Agendas
                  </NavLink>
                  <NavLink
                    onClick={() => setIsOpenSidebar(false)}
                    exact
                    to="Doadores"
                  >
                    Doadores
                  </NavLink>
                  <NavLink
                    onClick={() => setIsOpenSidebar(false)}
                    exact
                    to="Cancelamentos"
                  >
                    Cancelamentos
                  </NavLink>
                  <NavLink
                    onClick={() => setIsOpenSidebar(false)}
                    exact
                    to="TodosGruposDoadores"
                  >
                    Grupos de Doadores
                  </NavLink>
                </>
              ) : null}
            </SidebarContent>
          )}
        </div>
        {isOpenSidebar & (typeUser !== USER_PUBLIC) ? (
          <NavLink
            exact
            to="/Login"
            onClick={() => {
              localStorage.removeItem("token");
              setTypeUser(
                isAuthenticatedAdmin()
                  ? USER_ADMINISTRADOR
                  : isAuthenticated()
                  ? USER_DOADOR
                  : USER_PUBLIC
              );
              setIsOpenSidebar(false);
              window.location.replace("Login");
            }}
          >
            Sair
            <img src={LogOut} alt="Sair" />
          </NavLink>
        ) : null}
      </SidebarContainer>
      <ModalRecADM
        showModal={showModalRecAdm}
        setShowModal={setShowModalRecAdm}
      />
      <ModalCancelarInscricao
        showModal={showModalCancelarInscricao}
        setShowModal={setShowModalCancelarInscricao}
      />
    </>
  );
}

export default Sidebar;
