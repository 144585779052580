import { useState, useEffect } from "react";
import {
  Background,
  Container,
  ContentForm,
  Div,
  Titulo,
  Form,
  FormController,
  DivBotao,
  Label,
  FormEsquerda,
  FormDireita,
  DivCentral,
  Hr,
  // Select,
} from "./style.js";
import api from "../../services/api";
import Auth from "../../services/auth";
import swal from "sweetalert";
import InputMask from "react-input-mask";
import "../Login/inputMask.css";
import "./confirm.css";
import { IoEyeOutline, IoEyeOffOutline } from "react-icons/io5";
import { useHistory } from "react-router-dom";

const ConfirmData = () => {
  const history = useHistory();

  const [cpf_doador, setCpf_doador] = useState(Auth.token());
  const [nome, setNome] = useState("");
  const [data_nasc, setData_nasc] = useState("");
  const [cep, setCep] = useState("");
  const [email, setEmail] = useState("");
  const [telefone, setTelefone] = useState("");
  const [senha, setSenha] = useState("");
  const [visible, setVisible] = useState(false);
  // const [sexo, setSexo] = useState("");
  const [logradouro, setLogradouro] = useState("");
  const [complemento, setComplemento] = useState("");
  const [cidade, setCidade] = useState("");
  const [numero, setNumero] = useState("");
  const [bairro, setBairro] = useState("");
  const [estado, setEstado] = useState("");

  useEffect(() => {
    api
      .get(`/users/?cpf=${cpf_doador}`)
      .then((response) => {
        setNome(response.data.doadores[0].nome);
        setData_nasc(response.data.doadores[0].data_nasc);
        setCep(response.data.doadores[0].cep);
        setEmail(response.data.doadores[0].email);
        setTelefone(response.data.doadores[0].telefone);
        // setSexo(response.data.doadores[0].sexo);
        setComplemento(response.data.doadores[0].complemento);
        setLogradouro(response.data.doadores[0].logradouro);
        setCidade(response.data.doadores[0].cidade);
        setNumero(response.data.doadores[0].numero);
        setBairro(response.data.doadores[0].bairro);
        setEstado(response.data.doadores[0].uf);
      })
      .catch((error) => {
        if (error.response.status === 401) {
          localStorage.removeItem("token");
          history.push({
            pathname: "/",
          });
        }
      });
  }, [cpf_doador, history]);

  function onBlurCep(ev) {
    const { value } = ev.target;
    const cep = value.replace(/[^0-9]/g, "");
    if (cep.length !== 8) {
      return;
    }
    fetch(`https://viacep.com.br/ws/${cep}/json/`)
      .then((res) => res.json())
      .then((data) => {
        setLogradouro(data.logradouro);
        setBairro(data.bairro);
        setComplemento(data.complemento);
        setCidade(data.localidade);
        setEstado(data.uf);
        setComplemento("");
        setNumero("");
      });
  }

  async function handleSubmit(e) {
    e.preventDefault();

    const data = {
      cpf: cpf_doador,
      nome: nome,
      data_nasc: data_nasc,
      cep: cep,
      email: email,
      telefone: telefone,
      complemento: complemento,
      cidade: cidade,
      numero: numero,
      bairro: bairro,
      uf: estado,
      logradouro: logradouro,
      senha: senha,
      // sexo: sexo,
    };

    if (senha !== "") {
      try {
        const response = await api.patch(`/changepassword`, {
          data,
        });
        if (response.data.error)
          return swal("Atenção!", response.data.error, "warning");
        swal("Sucesso!", "Dados atualizados", "success");
      } catch (error) {
        swal("Atenção!", "Serviço indisponível no momento", "error");
      }
    }

    try {
      const response = await api.patch(`/update`, { data });
      if (response.data.error)
        return swal("Atenção!", response.data.error, "warning");
      swal("Sucesso!", "Dados atualizados", "success");
      setTimeout(() => {
        history.push({
          pathname: "/MeuAgendamento",
        });
      }, 1500);
    } catch (error) {
      swal("Atenção!", "Serviço indisponível no momento", "error");
    }
  }

  return (
    <>
      <Background>
        <Container>
          <ContentForm>
            <Titulo>
              <legend>Meu Cadastro</legend>
            </Titulo>
            <Form onSubmit={handleSubmit}>
              <DivCentral>
                <FormEsquerda>
                  &nbsp;Informações Pessoais
                  <Hr></Hr>
                  <FormController>
                    <Label>CPF:</Label>
                    <Div
                      type="text"
                      value={cpf_doador}
                      onChange={(e) => setCpf_doador(e.target.value)}
                      disabled
                    />
                  </FormController>
                  <FormController>
                    <Label>Nome completo:</Label>
                    <Div
                      type="text"
                      value={nome.toUpperCase()}
                      required
                      pattern="([A-ZÀ-Ú]* [A-ZÀ-Ú ]*$)"
                      title="Informe pelo menos um sobrenome"
                      onChange={(e) => setNome(e.target.value)}
                    />
                  </FormController>
                  <FormController>
                    <Label>Data de nascimento:</Label>
                    <InputMask
                      style={{ marginTop: 0, textAlign: "center" }}
                      mask="99/99/9999"
                      type="text"
                      value={data_nasc}
                      required
                      pattern="(?:((?:0[1-9]|1[0-9]|2[0-9])\/(?:0[1-9]|1[0-2])|(?:30)\/(?!02)(?:0[1-9]|1[0-2])|31\/(?:0[13578]|1[02]))\/(?:19|20)[0-9]{2})"
                      onChange={(e) => setData_nasc(e.target.value)}
                      className="Input2"
                    />
                  </FormController>
                  {/* <FormController>
                    <Label>Sexo Biológico:</Label>
                    <Select
                      required
                      value={sexo}
                      onChange={(e) => setSexo(e.target.value)}
                      title="Sexo Biológico (para fins de compatibilidade de doação)"
                    >
                      <option value="">Selecione uma opção</option>
                      <option value="M">Masculino</option>
                      <option value="F">Feminino</option>
                    </Select>
                  </FormController> */}
                  <FormController>
                    <Label>Celular:</Label>
                    <InputMask
                      style={{ marginTop: 0 }}
                      type="text"
                      required
                      mask="(99) 99999-9999"
                      pattern="([^0-9]{0,}([0-9]{2})[^0-9]{0,}([0-9]{5})[^0-9]{0,}([0-9]{4}).*)"
                      value={telefone}
                      onChange={(e) => setTelefone(e.target.value)}
                      className="Input2"
                    />
                  </FormController>
                  <FormController>
                    <Label>Email:</Label>
                    <Div
                      type="email"
                      value={email}
                      required
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </FormController>
                  <FormController>
                    <Label>Senha:</Label>
                    <Div
                      type={visible ? "text" : "password"}
                      value={senha}
                      onChange={(e) => setSenha(e.target.value)}
                    />
                    {visible ? (
                      <IoEyeOutline
                        size={22}
                        color="#000"
                        onClick={() => setVisible(!visible)}
                      />
                    ) : (
                      <IoEyeOffOutline
                        size={22}
                        color="#000"
                        onClick={() => setVisible(!visible)}
                      />
                    )}
                  </FormController>
                </FormEsquerda>
                <FormDireita>
                  &nbsp;Endereço
                  <Hr></Hr>
                  <FormController>
                    <Label>CEP:</Label>
                    <InputMask
                      style={{ marginTop: 0 }}
                      type="text"
                      mask="99999-999"
                      value={cep}
                      required
                      pattern="\d{5}-?\d{3}"
                      onChange={(e) => setCep(e.target.value)}
                      className="Input2"
                      onBlur={onBlurCep}
                    />
                  </FormController>
                  <FormController>
                    <Label>Logradouro (Rua/Avenida):</Label>
                    <Div
                      type="text"
                      value={logradouro}
                      onChange={(e) => setLogradouro(e.target.value)}
                      required
                      id="logradouro"
                    />
                  </FormController>
                  <FormController>
                    <Label>Número:</Label>
                    <Div
                      type="text"
                      value={numero}
                      onChange={(e) => setNumero(e.target.value)}
                      required
                    />
                  </FormController>
                  <FormController>
                    <Label>Bairro:</Label>
                    <Div
                      type="text"
                      value={bairro}
                      onChange={(e) => setBairro(e.target.value)}
                      required
                    />
                  </FormController>
                  <FormController>
                    <Label>Complemento:</Label>
                    <Div
                      type="text"
                      value={complemento}
                      onChange={(e) => setComplemento(e.target.value)}
                    />
                  </FormController>
                  <FormController>
                    <Label>Cidade:</Label>
                    <Div
                      type="text"
                      value={cidade}
                      onChange={(e) => setCidade(e.target.value)}
                      required
                    />
                  </FormController>
                  <FormController>
                    <Label>Estado:</Label>
                    <Div
                      type="text"
                      value={estado}
                      onChange={(e) => setEstado(e.target.value)}
                      required
                    />
                  </FormController>
                </FormDireita>
              </DivCentral>
              <DivBotao>
                <button
                  style={{ fontSize: 14, marginLeft: 20 }}
                  className="botao"
                >
                  Atualizar&nbsp;
                </button>
              </DivBotao>
              {/* <br />
              <br /> */}
              {/* <FormDireita>
                                <Link to={"/SelectTime"}>Inserir Doação</Link>
                            </FormDireita> */}
            </Form>
          </ContentForm>
        </Container>
      </Background>
    </>
  );
};

export default ConfirmData;
